@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

















.main {
  height: 100vh;
}
