.wrapping-button.v-btn {
    &.v-size--default {
         height: auto;
         min-height: 36px;
     }

    .v-btn__content {
        width: 100%;
        white-space: normal;
        text-align: center;
    }
}

.data-table-pointer {
  > * tr {
    cursor: pointer;
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.empty-state-text--dark {
  color: rgba(0, 0, 0, 0.87);;
}

.text-field__header {
  font-weight: 500;
  font-size: size(17);
  line-height: size(21);
  letter-spacing: size(0.1);
  text-transform: uppercase;
  margin-bottom: 1rem;
}